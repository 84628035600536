/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap"); */
/* Import the fonts */
@import url("https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@300;400;500;600;700&display=swap");

/* Apply the fonts to different elements */
body {
  font-family: "Urbanist", sans-serif !important;
  /* background: linear-gradient(90deg, #03e57d, #07a6d8) !important; */
  background: #061c17 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0px !important;
}

.tag-body.card-body {
  padding: 25px 16px;
}

img {
  height: auto;
}

.pd-top {
  padding-top: 25px !important;
}

.navWraper.scrolled .pd-top {
  padding-top: 10px !important;
}

.gc-tagbtn {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  column-gap: 12px;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
  padding: 0px !important;
  margin: 10px 0px;
}

.gc-tagbtn:hover {
  color: #fff;
}

.outerDiv {
  background-repeat: no-repeat;
  background-image: url("../Assets/Images/top-banner-bg.webp") !important;
  height: 796px;
  background-size: cover !important;
}

.jazz_discount_outer table {
  border: solid 1px;
  margin-top: 20px;
}

.jazz_discount_outer table th,
td {
  border: solid 1px !important;
  padding: 5px;
}

.outerDiv2 {
  background-repeat: no-repeat;
  /* background-image: url("../Assets/Images/moreScreenBg.webp") !important;
  background-image: url("../Assets/Images/moreScreenBg.webp") !important; */
  background-size: cover !important;
}

.nav-item .nav-link {
  font-weight: 500;
}

.nav-item .nav-link:hover,
.nav-item.active .nav-link {
  background: -webkit-linear-gradient(360deg, #03e57d, #07a6d8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.2s ease-in-out;
}

.noDataStyle {
  color: #fff;
  text-align: center;
  padding: 60px 0px;
  font-size: 18px;
  font-weight: 500;
  min-height: 350px;
}

.loading-spinner-small {
  width: 24px;
  height: 24px;
  border: 5px solid lightgrey;
  /* Light grey */
  border-top: 5px solid #4f2c58;
  /* Black */
  border-radius: 150%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container-small {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .bannerBtns .downloadgame:hover {
  background-color: #3f304c;
} */

.mainBgSection {
  background-image: url("../Assets/Images/homepage-bg.webp");
  background-size: cover;
  background-position: center;
}

#text {
  font-style: normal;
  font-weight: 800;
  font-size: 108px;
  text-transform: uppercase;
  font-family: var(--bs-body-font-family);
}

/* default button */
.d-btn {
  font-weight: 600;
  border-radius: 10px;
  padding: 13px 70px;
  color: #fff;
  background: linear-gradient(90deg, #03e57d, #07a6d8);
  box-shadow: 0px 20px 100px 0px rgba(0, 204, 131, 0.3);
  transition: all 0.2s;
  border: none;
  text-decoration: none;
}

.card {
  transition: all 0.2s ease-in-out;
}

.card:hover {
  box-shadow: 0px 20px 100px 0px rgba(0, 204, 131, 0.3);
}

.d-btn:hover {
  box-shadow: none;
  color: #161917;
}

/* dark button */
.dark-d-btn {
  background: rgba(0, 0, 0, 0.5);
  border: solid 2px #fff;
  padding: 11px 70px;
}

.dark-d-btn:hover {
  color: #fff;
  background-color: #000;
}

.card .d-btn {
  letter-spacing: 0;
  padding: 4px 12px;
  font-size: 11px;
}

.d-btn-play {
  width: 151px;
  height: 35px;
}

.d-btn-play2 {
  width: 185px;
  height: 35px;
}

/* nav styling */
.navbar-expand-lg .navbar-nav {
  column-gap: 15px;
}

.navWraper .sub-btn {
  background-clip: content-box, border-box;
  background-image: linear-gradient(hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0)),
    linear-gradient(90deg, #03e57d, #07a6d8);
  background-origin: border-box;
  border: 2px solid transparent;
  box-shadow: inset 2px 1000px 1px #161917,
    0px 20px 100px 0px rgba(0, 204, 131, 0.3);
  padding: 8px 30px;
}

.navWraper .sub-btn:hover {
  box-shadow: none !important;
}

.nav-search {
  border: none;
  outline: none;
  background-color: transparent;
}

.nav-search img {
  width: 34px;
  height: 34px;
}

.navMargin {
  /* margin-top: -9px !important; */
}

.navLiColor {
  color: gainsboro !important;
  font-size: 13px !important;
}

.gwLogoDim {
  /* height: 93px;
  width: 113px; */
}

button.button {
  border-radius: 100rem;
  padding: 1rem;
  font-size: 1rem;
  padding: 0.5rem 3rem;
  color: white !important;
  background-origin: border-box;
  background-clip: content-box, border-box;
  border: linear-gradient(#cd3aff 100%, #1eccd6 100%, #ffffff 15%) !important;
  line-height: 19px;
  font-size: 14px;
  font-weight: 700;
  background: linear-gradient(60deg, #cd3aff, #1eccd6) border-box;
  background-blend-mode: overlay;
  backdrop-filter: blur(20px);
}

.searchIcon {
  color: white !important;
}

.dis-mobile {
  display: none !important;
}

.dis-desk {
  display: block !important;
}

.navbarWrapper {
  width: 100%;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* nav styling */
/* footer styling */
.footer {
  background: linear-gradient(180deg,
      rgba(0, 204, 131, 0.03) 0%,
      rgba(1, 156, 184, 0.03) 96.05%),
    rgba(20, 20, 20, 0.65) !important;
  padding: 50px 0px 30px;
}

span.copyright {
  color: #fff;
  text-align: center;
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 137.5% */
  padding-top: 8px;
}

.footer-social-icon {
  display: flex;
  justify-content: flex-end;
  column-gap: 22px;
  margin-top: 14px;
}
.footer-social-icon a svg:hover{
  opacity: 0.6;
  transition: 0.3s linear;
}
.border-bottom {
  background: #ffffff;
  opacity: 0.2;
  margin: 12px 0px;
}

.bottom-footer {
  margin-top: 30px;
}

.footLinks {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  position: relative;
}

.footLinks a {
  text-decoration: none;
  color: #fff;
  position: relative;
  max-width: max-content;
  padding-bottom: 6px;
  font-size: 13px;
  font-weight: 300;
}

.footLinks .f-link a:hover,
.footLinks li:hover {
  color: #5cd7d3;
}

/* .footLinks a:after {   
  content: "";
  width: 0;
  height: 1px;
  position: absolute;
  bottom: 4px;
  left: 0;
  right: 0;
  background: #fff;
  border-radius: 15px;
  transition: width 0.2s;
} */
/* .footLinks a:hover:after {
  width: 100%;
  transition: width 0.2s;
} */
.footLinks {
  list-style: none;
  text-align: left;
}

.footLinks li {
  margin-bottom: 20px;
}

img.footLogo {
  width: 100%;
  max-width: 195px;
}

.followText {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  text-align: right;
}

/* footer styling */

.allCardBg {
  background: linear-gradient(0deg,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0) 100%) !important;
  backdrop-filter: blur(22px);
  color: white;
  border-radius: 14px 14px 20px 20px;
}

.outerDivLiveStrm {
  background-repeat: no-repeat;
  background-image: url("../Assets/Images/top-banner-bg.webp") !important;
  height: 100%;
  margin-bottom: 60px;
}

.nav-item button.nav-link.active {
  background: transparent;
  border: none;
  color: #ffffff !important;
  font-style: normal;
  font-weight: 600;
  font-size: 20 px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.374px;
}

.nav-item button.nav-link {
  color: grey !important;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.374px;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: unset;
}

.navWraper {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: transparent;
}

.navWraper.scrolled {
  background: #061c17;
  backdrop-filter: blur(22px);
  transition: 0.3s linear;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.navWraper.scrolled .gwLogoDim {
  /* height: 60px;
  width: 60px; */
}

.top-padding {
  padding-top: 100px;
}

.nav_btns.mt-2 {
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.userProfile img {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  border-top: 2px solid #c144fc;
  border-right: 2px solid #38a5cb;
  border-bottom: 2px solid #38a5cb;
  border-left: 2px solid #c144fc;
  object-fit: contain;
}

li.profile__li img {
  border: unset;
}

.userProfile {
  position: relative;
}

/* Dropdown content (hidden by default) */
.userProfile .dropdown-content {
  display: none;
  position: absolute;
  right: 5px;
  top: 45px;
  background-color: #061c17;
  backdrop-filter: blur(35px);
  border-radius: 12px;
  min-width: 261px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 15px 20px 10px;
}

/* Dropdown content links */
.userProfile .dropdown-content ul li {
  color: black;
  padding: 12px 0px;
  text-decoration: none;
  display: block;
  border-bottom: 1.72698px solid rgba(204, 204, 204, 0.2);
}

.userProfile .dropdown-content ul {
  padding-left: 0px;
}

.userProfile .dropdown-content ul .profile__li {
  display: flex;
  column-gap: 12px;
  align-items: center;
}

.userProfile .dropdown-content ul .profile__li span a {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  text-decoration: none;
}

.userProfile .dropdown-content ul .profile__li .manageProfile span {
  display: flex;
}

.userProfile .dropdown-content ul .profile__li .manageProfile span a {
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #fff;
}

.userProfile .dropdown-content ul .profilename {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
}

.userProfile .dropdown-content::before {
  content: "";
  position: absolute;
  top: -11px;
  right: 15px;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #061c17;
}

.userProfile .dropdown-content ul {
  list-style: none;
}

/* Dropdown on hover */
.userProfile:hover .dropdown-content {
  display: block;
}

li.profile__li svg {
  min-width: 30px;
  cursor: pointer;
}

/* Triangle arrow */
.userProfile .dropdown-content ul li:last-child {
  border-bottom: unset;
  padding-bottom: 0px;
}

/* nav styling */
.main-padding {
  padding: 80px 0px;
}

/* new tabs styling */
.tabsItems {
  padding: 20px 0px;
  background-color: rgba(62, 70, 115, 0.32);
  backdrop-filter: blur(107.167px);
}

.tabsItems .tabsbtns button {
  font-weight: 600;
  font-size: 16px;
  color: #c4c4c4;
  background-color: unset;
  border: unset;
  margin-right: 20px;
}

.tabsItems .tabsbtns button.active {
  color: #fff;
}

.tabsItems .tabsbtns button:hover {
  color: #fff;
}

.tabsbtns {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tabsSearchBtn .searchIcon input {
  background: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  color: #fff;
  padding-right: 15px;
}

.tabsSearchBtn .searchIcon input::placeholder {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.374px;
  color: #c4c4c4;
}

.crossBtn {
  display: none;
}

/* new tabs styling */
/* comming soon modal  */
.cs-Modal .modal-content {
  /* background-color: red; */
  background-image: linear-gradient(hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0)), linear-gradient(90deg, #03e57d, #07a6d8);
  border-radius: 16px;
  border: unset;
  padding: 130px 0;
}

.cs-Modal .CS-Logo {
  width: 100%;
  max-width: 200px;
  /* height: 65px; */
  margin-top: 30px;
  display: none;
}

.cs-Modal .modal-header {
  padding: 0px;
  border-bottom: 0px;
  margin: 0 auto;
}

.cs-Modal .modal-dialog {
  max-width: 600px;
}

.cs-Modal button.btn-close {
  position: absolute;
  right: 30px;
  top: 25px;
  background: url("../Assets/Images/x-circle.png");
  color: #ffff;
  background-size: cover;
  opacity: unset;
  width: 12px;
  height: 10px;
  background-repeat: no-repeat;
}

.cs-Modal .comingsoonImg {
  width: 100%;
  max-width: 330px;
  display: block;
  margin: 0 auto;
  display: none;
}

.cs-Modal .modal-body {
  border-bottom: 0px;
  margin: 0 auto;
  padding: 0;
  /* padding-bottom: 8px; */
}

.cs-Modal .modal-body h2 {
  color: #fff;
  font-weight: 800;
  font-size: 30px;
  text-align: center;
  /* margin-top: 20px; */
  margin-bottom: 0px;
  margin: 15px 0px !important;
}

.cs-Modal .modal-footer {
  border-top: unset;
  justify-content: center;
  padding-bottom: 30px;
  padding-top: 0px;
  padding: 0;
}

.cs-Modal .modal-footer .otpBtn {
  width: 100%;
  margin-top: 0px;
  outline: none;
  padding: 3px 0px;
  max-width: 160px;
  font-size: 12px;
  color: #fff;
  font-weight: 700;
  display: none;
}

/* comming soon modal  */
/* .tag-body{
    min-height: 115px;
} */

/* gloabal Search styling */
.globalsearchwraper {
  padding-top: 150px;
}

/* 121B20 */
.searchBox {
  padding: 20px 40px;
  border-radius: 20px;
  margin-bottom: 40px;
}

.g-s-btn input {
  border-radius: 20px;
  border: none;
  height: 40px;
  padding-left: 20px;
  width: 100%;
  background: rgba(99, 99, 99, 0.3);
  outline: none;
  color: #cac4d0;
}

.g-s-btn .searchIcon #searchButton {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
}

.g-s-btn .searchIcon #searchButton svg {
  fill: #cac4d0;
}

.searchIcon {
  position: relative;
}

.globalSearchStreamer {
  margin: 60px 0px 0px;
}

.m-btm {
  padding-bottom: 60px !important;
}

.globalSearchStreamer .m-stram-card {
  margin-bottom: 40px;
}

.globalSearchModal .modal-header {
  border-bottom: 0px;
}

.innerSearch {
  position: relative;
}

.innerSearch .inputsearchicon {
  position: absolute;
  top: 50%;
  right: 14px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transform: translateY(-50%);
}

.innerSearch #search-input {
  width: 100%;
  border-radius: 35px;
  padding: 7px 21px;
  outline: none;
}

.globalSearchModal .modal-content {
  background-color: transparent;
}

.globalSearchModal .btn-close {
  background-image: url("../Assets/Images/x-circle.png") !important;
  opacity: 1 !important;
  position: absolute;
  right: -20px;
  /* top: -198%; */
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.95) !important;
  opacity: unset !important;
}

.mainBgSection .globalsearchwraper .tabsItems {
  background-color: transparent;
  backdrop-filter: unset;
  padding-top: 0px;
}

.g-s-games {
  padding: 40px 0px;
}

.global-live-stream-wraper {
  margin-top: 30px;
}

.gc-gs-seeall {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gc-gs-seeall p {
  color: #fff;
  font-weight: 700;
  font-size: 16px;
}

.gc-see-all {
  text-decoration: none;
}

.gc-see-all:hover {
  opacity: 0.6;
}

/* gloabal Search styling */

/* leaderboard styling */
.leader-board-banner {
  position: relative;
}

.leaderBoardText {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.lbwrap {
  min-height: 800px;
}

.leaderBoardText .mainHead,
.leaderBoardText .shortText {
  text-align: left;
}

.leaderBoardText .shortText {
  width: 100%;
  max-width: 70%;
}

.luckyWinner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 17px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  margin-bottom: 20px;
}

.luckyWinner:last-child {
  border-bottom: unset;
}

.lw-left {
  column-gap: 15px;
}

.lw-left span,
.lw-left p,
.luckyWinner .price {
  color: #ffff;
  font-size: 14px;
}

.leaderboard-table {
  background-image: url("../Assets/Images/leaderboard-table-bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 80px;
}

.lw-left span {
  width: 30px;
  height: 30px;
  background: #6e6e6e;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  margin-right: 10px;
}

.lw-left span.winner {
  background-color: #03e57d;
}

.trophy {
  width: 24px;
}

.hr-line {
  border-bottom: 1px solid rgba(255, 255, 255, 0.33);
  margin: 10px 0px 60px;
}

.leadertext {
  color: #c4c4c4;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.374px;
}

/* user profile */
.userProfileImg {
  width: 250px;
  border-radius: 50%;
}

/* leaderboard styling */
@media (max-width: 1199px) {
  .navbar-expand-lg .navbar-nav {
    flex-direction: column !important;
  }

  .nav-search {
    display: none;
  }

  #text {
    font-size: 96px;
  }

  ul.navbar-nav {
    position: absolute;
    left: 0;
    top: 67px;
    background: #fff;
    z-index: 99;
    width: 165px;
    padding: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }

  /* #navbarNav ul.navbar-nav li{
    border-bottom: 1px solid grey;
  } 
  */
  .nav-item.active .nav-link {
    color: black !important;
    font-weight: bold;
  }

  .navLiColor {
    color: black !important;
    font-size: 13px !important;
  }

  nav.navbar {
    padding-top: 20px;
  }

  .nav_btns {
    display: flex;
  }

  .m-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    display: none;
  }

  .m-overlay.show {
    display: block;
    /* Show the overlay when the "show" class is added */
  }
}

@media (min-width: 340px) and (max-width: 767px) {
  .navWraper .sub-btn {
    padding: 5px 20px;
    font-size: 13px;
  }
  .mainBgSection {
  }

  .cs-Modal .comingsoonImg {
    max-width: 150px;
  }

  .cs-Modal .modal-body h2 {
    margin-top: 0px;
    font-size: 15px;
  }

  /* tabs css */
  .tabsLeftside {
    width: 320px;
    overflow-x: scroll;
    white-space: nowrap;
    padding-bottom: 0px;
  }

  .tabsLeftside::-webkit-scrollbar {
    display: none;
  }

  .tabsSearchBtn {
    background: #555555;
    display: flex;
    align-items: center;
    border-radius: 30px;
    padding: 7px 7px;
  }

  .tabsItems .tabsbtns button {
    color: rgba(255, 255, 255, 0.3);
  }

  .tabsSearchBtn .searchIcon input::placeholder {
    font-size: 14px;
  }

  .tabsItems .tabsbtns button {
    margin-right: 0px;
  }

  .tabsSearchBtn .searchIcon input {
    display: none;
    width: 130px;
  }

  .searchIcon {
    color: white !important;
    display: flex;
  }

  /* tabs css */
  .navWraper.scrolled .navbarWrapper.flex {
    padding-top: unset;
  }

  .footLinks .tcpp {
    justify-content: center;
  }

  .nav-item.active .nav-link {
    color: #fff !important;
  }

  .instant {
    font-size: 30px !important;
  }

  #text {
    font-size: 40px;
  }

  .navLiColor {
    color: #fff !important;
  }

  .navbar-toggler {
    background: #434343 !important;
    padding: unset !important;
    border-radius: 22px !important;
  }

  .navWraper.scrolled .navbar-toggler {
    background: transparent !important;
  }

  .navbar-toggler-icon {
    background-size: 60% !important;
  }

  .navbar-toggler:focus {
    box-shadow: unset !important;
  }

  .dis-mobile {
    display: block !important;
  }

  .dis-desk {
    display: none !important;
  }

  .footer {
    -webkit-backdrop-filter: blur(3.5px);
    backdrop-filter: blur(22px);
    background-color: transparent;
    padding: 30px 0;
  }

  img.footLogo {
    /* max-width: 40px; */
  }

  .inputWraper {
    max-width: 170px;
  }

  .newsSteller {
    justify-content: flex-end;
  }

  .newsStellertext {
    font-size: 14px;
  }

  span.footLinks {
    font-size: 8px;
  }

  .footLinks a {
    font-size: 8px;
    font-weight: 500;
  }

  .twitter,
  .linkedin,
  .facebook,
  .instagram {
    width: 20px !important;
    height: 20px !important;
  }

  .bottom-footer.flex {
    justify-content: space-between;
  }

  .footLinks {
    text-align: left;
    padding: 0;
  }

  .followText {
    font-weight: 300;
    font-size: 8px;
    margin-top: 4px;
  }

  .footer-social-icon {
    gap: 5px;
    margin-top: 0;
  }

  .footer-social-icon a svg {
    width: 15px;
  }

  .footLinks li {
    margin-bottom: 0;
  }

  span.copyright {
    font-size: 10px;
    padding-top: 15px;
  }

  .border-bottom {
    width: 100%;
    max-width: 100%;
    margin-left: auto;
  }

  .bottom-footer {
    margin-top: 0;
  }

  .border-bottom {
    margin-top: 5px;
    margin-bottom: 0px;
  }

  ul.navbar-nav {
    position: absolute;
    left: 0;
    top: 59px;
    width: 165px;
    z-index: 9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    background-color: #061e18;
    padding: 15px;
    border: 1px solid #03e57e;
    border-radius: 12px;
  }

  .nav_btns {
    display: flex;
  }

  .sendmailIcon {
    max-width: 22px;
    height: 22px;
  }

  .newsteller {
    height: 30px;
  }

  .d-btn {
    padding: 4px 18px;
  }

  .global-live-stream-wraper {
    padding-bottom: 0px;
  }

  .globalSearchModal .btn-close {
    top: 6px;
    right: 10px;
  }

  .globalSearchModal .modal-dialog-centered {
    align-items: flex-start;
  }

  .globalsearchwraper {
    padding-top: 90px;
  }

  .nav_btns.mt-2 {
    margin-top: 0px !important;
  }

  .globalSearchStreamer {
    margin: 20px 0px 0px;
  }

  .gs-input {
    display: block !important;
  }

  .socialIcon {
    column-gap: unset;
  }

  body {
    /* background-color: #1d1d1d !important; */
    background-color: #061c17 !important;
  }

  /* leader board */
  .leaderboard-table {
    padding-top: 30px;
  }
  .lbwrap{
    min-height: 400px;
  }

  .leaderboard-table .hr-line {
    margin: 15px 0;
  }

  .lw-left {
    column-gap: 5px;
  }
  .leaderBoardText .shortText {
    font-size: 10px;
  }
  .leaderBoardText {
    top: 60%;
  }
  .cs-Modal .modal-content{
    padding: 80px 0;
    width: 80%;
    margin: auto;
  }
  .searchBox{
    padding: 20px 0;
  }
}
