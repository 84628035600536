/* body{ margin:0px; font-family: 'Roboto', sans-serif;} */

.jazz_discount_outer {
    background: url('../Assets/Images/tnc-bg.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    float: left;
    margin: 0px;
    padding: 0px;
    width: 100%;
}

.discount_inner {
    float: left;
    margin: 0px;
    padding: 20px 0px;
    width: 100%;
    color: #fff;
}

.discount_inner h2 {
    float: left;
    width: 100%;
    position: relative;
    padding: 25px;
    border: solid 1px #03e57d;
    margin-bottom: 20px !important;
    font-size: 28px;
    color: #03e57d;
    text-align: center;
    border-radius: 10px;
    backdrop-filter: blur(10px);
}


.discount_inner h3 {
    float: left;
    width: 100%;
    color: #03e57d;
    margin: 0px 0 10px 0;
    font-size: 18px;
    font-weight: 600;
}

.discount_inner p {
    float: left;
    width: 100%;
    color: #fff;
    margin: 0px 0 18px 0;
    font-size: 14px;
    font-weight: 400;
}

.discount_inner a {
    color: #03e57d;
}

.jazz_discount_outer ul {
    padding-left: 30px;
    margin-bottom: 10px;
}

.jazz_discount_outer li {
    text-align: left;
    font-size: 14px;
    line-height: 1.42857143;
    color: #fff;
    list-style-type: disc;
}

.jazz_discount_outer table th{
    color: #03e57d;
}
.jazz_discount_outer table th, td{
    text-align: center;
}