/* GC banner starts */
.GC-banner {
  position: relative;
}

.explorePopulargame {
  position: absolute;
  top: 56%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mainHead {
  font-size: 40px;
  color: #fff;
  text-align: center;
  font-family: "Dela Gothic One", sans-serif !important;
}

.shortText {
  font-size: 14px;
  color: #fff;
  text-align: center;
  margin: 15px 0px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
}

.hp-banner {
  display: flex;
  justify-content: center;
  column-gap: 15px;
}

.explorePopulargame .shortText {
  font-size: 14px;
  color: #fff;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  width: 100%;
  max-width: 78%;
  display: block;
  margin: 30px auto;
}

/* GC banner ends */
/* gc instant games starts*/
.instantGamesTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

.instantGamesTop .shortText {
  width: 100%;
  max-width: 50%;
  text-align: right;
}

.instgame__wraper .slick-next:before,
.instgame__wraper .slick-prev:before {
  content: none;
}

.instgame__wraper .d-btn {
  margin: 40px auto 0px;
  display: inline-block;
}

.see-all-btn {
  text-align: center;
}

/* gc instant games  ends*/
/* gc market place  starts*/
.marketTop .shortText {
  width: 100%;
  max-width: 50%;
  margin: 5px auto 35px;
}

.marketCard {
  border-radius: 22px;
  background: #2b2b2b;
  box-shadow: 0px 7.72607px 38.63037px 0px rgba(0, 0, 0, 0.25);
  padding: 21px 30px 30px;
}

.marketCard .marketImg {
  width: 100%;
  border-radius: 15.289px;
}

.marketgametitle {
  color: #fff;
  text-align: left;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 88.9%;
  margin: 40px 0px 10px;
}

.mark-icon-wraper {
  display: flex;
  column-gap: 12px;
  align-items: center;
  margin: 15px 0px;
}

.mark-icon-wraper span {
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 88.9%;
  text-transform: capitalize;
}

.pricewraper p {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  text-transform: capitalize;
}

.pricewraper span {
  color: #019cb8;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 88.9%;
  text-transform: capitalize;
  margin-left: 5px;
}

.pricewraper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
}

.pergift {
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
}

.marketbtn .d-btn {
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
}

.marketswiper {
  position: relative;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  content: "" !important;
  width: 50px;
  height: 50px;
  z-index: 99;
  position: absolute;
}

.swiper-button-prev:after {
  background-image: url("../Assets/Images/swiper-left-arrow.webp") !important;
  /* left: -80px; */
}

.swiper-button-next:after {
  background-image: url("../Assets/Images/swiper-right-arrow.webp") !important;
  /* right: -80px; */
}

/* gc market place  ends*/

/* gc download section starts*/
.downloadGamesWraper .slick-next:before,
.downloadGamesWraper .slick-prev:before {
  content: none;
}

.slick-next,
.slick-prev {
  display: none !important;
}

.dg-ls {
  background-color: #141414;
  padding: 25px 60px 20px;
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.dg-ls .d-btn{
  display: inline-block;
  width: fit-content;
}
.dg-ls .mainHead {
  text-align: left;
  margin-bottom: 0px;
}

.dg-ls .shortText {
  text-align: left;
  margin-top: 0px;
}

/* gc download section ends*/
/* game competition  */
.gcom-wraper {
  width: 100%;
  background-image: url("../Assets/Images/game-competitions.webp");
  height: 382px;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 80px 40px 0px;
  border-radius: 22px;
  background-position: right;
}

.gcom-wraper .mainHead {
  text-align: left;
  line-height: 60px;
  width: 100%;
  max-width: 40%;
}

.gcom-wraper .shortText {
  text-align: left;
  width: 100%;
  max-width: 29%;
}

/* game competition  */

.cardBtnAction {
  border-radius: 20px;
  border: 0px;
  background: #4c4c4c;
  border-radius: 14px;
  color: white;
  font-size: 9px;
  width: 48px !important;
  cursor: default !important;
}

.cardBtnLvlUp {
  border-radius: 20px;
  border: 0px;
  background: #4c4c4c;
  border-radius: 14px;
  color: white;
  font-size: 9px;
  width: 53px;
  cursor: default !important;
}

.cardTitle {
  font-size: 15px !important;
}

.cardSecText {
  font-size: 11px;
  color: #fff;
}

.slick-slideBanner slick-slide {
  outline: none;
  width: 207px !important;
}

/* m styling */

/* banner section */
.blurImg {
  width: 100%;
  max-width: 100%;
}

.blurImg {
  width: 100%;
  max-width: 30%;
}

img.main-hero-img {
  width: 100%;
  max-width: 53%;
}

.heroimg {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  max-width: 50%;
}

.bannerWrapper {
  padding-top: 160px;
  margin-left: 50px;
}

.bannerinnerWraper {
  position: relative;
}

.bannerWrapper .popText {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #c4c4c4;
  margin-bottom: 0px;
}

.dummytext {
  margin-bottom: 30px;
}

.dummytext p {
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  text-transform: capitalize;
  color: #c4c4c4;
  margin-bottom: 0px;
}

/* banner section */
.slick-dots {
  position: inherit !important;
  bottom: 0px !important;
  margin-top: 10px !important;
}

.slick-dots li {
  background: rgba(255, 255, 255, 0.62);
  border-radius: 25px;
  opacity: 0.6;
  transition: all 0.2s ease 0s;
  margin: 0px 7px 0px 0px !important;
  height: 2px !important;
}

.slick-dots li:hover {
  opacity: 1;
}

.slick-dots li.slick-active {
  width: 30px;
  opacity: 1;
}

.slick-dots li button::before {
  height: 7px !important;
  content: "" !important;
  opacity: 1 !important;
}

.recomendedstream {
  color: #fff;
  padding-top: 40px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #c4c4c4;
}

/* instantGames */

.games {
  text-transform: uppercase;
}

.compText {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  width: 100%;
  max-width: 255px;
  margin: 10px 0px 0px;
}

.hero-mobile {
  display: none;
}

.center__Slide .row {
  margin-left: unset;
  margin-right: unset;
}

.game-card-Img {
  width: 100%;
  /* height: 190px; */
  border-radius: 30px;
  cursor: pointer;
  max-height: 220px;
}

/* m styling */

.playgamesWraper.flex {
  margin-bottom: 9px;
  margin-top: -25px;
}

.playgamesWraper.flex a p:hover {
  color: #9990ad;
}

a.text-reset.text-decoration-none:hover {
  color: #9990ad !important;
}

.hp-card.card {
  overflow: hidden;
  padding: 12px 6px;
  border-radius: 22px;
  background-color: #1e2521;
}

.hp-card img {}

/* .hp-card.card img:hover {
    transition: .3s;
    transform: scale(1.06);
  } */
.hp-card.card .card-title {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 5px;
}

/* loader */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.hploading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid lightgrey;
  /* Light grey */
  border-top: 10px solid #3c7fa1;
  /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.hpspinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  height: 100vh;
}

/* bannerSlider */
.bannerSlider {
  width: 100%;
  max-width: 483px;
  padding: 17px 20px 15px 0px;
  background-color: #3e3b5a;
  position: absolute;
  bottom: 8px;
}

.bannerSlider .flex {
  align-items: flex-end;
}

.sliderText {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 0 40px;
  margin-bottom: 7px;
}

.sliderText h3,
.sliderText a {
  font-size: 14px;
  line-height: 22px;
  color: rgba(255, 255, 255, 1) !important;
}

.sliderText a {
  cursor: pointer;
}

a {
  cursor: pointer;
}

.bannerSlideWraper {
  width: 100%;
  max-width: 200px;
  margin-left: 40px;
}

.sliderIcon {
  width: 114px;
  height: 76px;
}

.tempSlider {
  width: 192px !important;
  height: 104px;
  cursor: pointer;
  border-radius: 7px;
}

/* bannerSlider */
/* hero slider */
.heroSlider .banner-slide img {
  width: 100%;
  /* height: 100vh; */
}

.banner-slide {
  outline: none;
}

.mainBannerBg {
  position: relative;
  /* height: 100vh; */
  /* pointer-events: none; */
}

.center__Slide {
  margin-top: -10px;
}

.gift-price-info {
  line-height: 13px;
}

.gift-price {
  font-size: 14px;
}

.gift-title {
  color: #c4c4c4;
}

/* hero slider */

.download__game__wraper .card img {
  cursor: pointer;
}

@media (min-width: 340px) and (max-width: 767px) {
  .mainHead {
    font-size: 24px;
  }

  .explorePopulargame {
    width: 100%;
  }

  .slick-dots {
    display: none;
  }

  .explorePopulargame .shortText {
    font-size: 13px;
    margin: 12px auto;
  }

  .instant__game__slider .card:not(.slick-center) {
    margin-right: 20px;
  }

  .hp-banner {
    display: flex;
  }

  .hp-banner button {
    font-size: 12px;
  }

  .hp-banner {
    display: flex;
    flex-wrap: nowrap;
  }

  .hp-banner {
    position: unset !important;
  }

  .mob-hp-banner {
    width: 100%;
    height: 380px;
  }

  .hp-banner .d-btn {
    /* max-width: 135px !important; */
    max-width: 100% !important;
    margin-top: 6px;
  }

  .explorePopulargame .mainHead {
    line-height: 40px;
  }

  /* instant games */
  .instantGamesTop {
    flex-direction: column;
    padding-top: 40px;
    margin-bottom: 20px;
  }

  .instantGamesTop .shortText {
    max-width: 1000%;
    text-align: center;
  }

  .instgame__wraper .d-btn {
    margin: 27px auto 0px;
    width: 71%;
    padding: 10px 0px;
  }

  .marketTop .shortText {
    max-width: 100%;
    margin: 20px auto;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    display: noen;
    content: none !important;
  }

  .main-padding {
    padding: 40px 0px;
  }

  .dg-ls {
    padding: 25px 30px 20px;
    margin-bottom: 25px;
    border-radius: unset;
  }

  .dg-ls .mainHead {
    text-align: center;
  }

  .dg-ls .shortText {
    text-align: center;
    margin: 20px 0px;
  }

  .dg-ls .d-btn {
    width: 100%;
    margin: 0 auto;
    padding: 10px 0px;
    text-align: center;
  }

  .gcom-wraper .mainHead {
    max-width: 100%;
    font-size: 18px;
    line-height: 22px;
  }

  .gcom-wraper {
    height: unset;
    padding: 31px 20px;
    background-size: cover;
    background-position: right;
  }

  .gcom-wraper .shortText {
    max-width: 90%;
    font-size: 12px;
    margin: 15px 0px 10px;
  }

  .marketCard {
    padding: 20px;
  }

  /* landing page responsiveness ends */
}