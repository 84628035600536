.landing-header {
  margin: 0px;
  padding: 0;
  width: 100%;
  background-image: url("../Assets/Images/web_land_header_bg.png") !important;
  max-height: 77px;
  border-bottom: solid 2px #d4141c;
  background-repeat: repeat;
}
.landingWraper {
  width: 100%;
  /* height: calc(100vh - 77px); */
  background-color: #0e0e0e;
  padding-bottom: 51px;
}
.landing-box {
  width: 360px;
  margin: 0 auto;
  padding-top: 40px;
}
.landing-box img {
  width: 100%;
}
.form-wraper {
  border: 2px solid #fff;
  border-radius: 16px;
  padding: 17px;
  width: 100%;
  max-width: 92%;
  margin: 20px auto 0px;
}
.form-wraper .e-m-n {
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 20px;
  text-align: center;
}
.input__group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.input__group input {
  border-radius: 10px;
  outline: none;
  border: none;
  height: 40px;
  padding-left: 7px;
  background-color: #6a6a6a;
  width: 100%;
  max-width: 82%;
  color: #fff;
  font-weight: 500;
}
.input__group input::placeholder {
  color: #fff;
  font-size: 14px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.form-wraper .inputgrouptext {
  font-size: 14px;
  color: #fff;
  border: none;
  font-weight: 500;
}
.inputgroupprepend {
  padding: 0px 10px;
  height: 40px;
  text-align: center;
  background-color: #6a6a6a;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-btn {
  margin: 20px 0px;
}
.short-text p:first-child {
  margin: 17px 0px 12px;
  font-size: 10px;
}
.form-btn button {
  width: 100%;
  border: none;
  outline: none;
  padding: 9px 30px 10px 30px !important;
  background-color: #ba131a;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  border-radius: 6px;
  min-width: 200px;
  cursor: pointer;
}
.form-btn button:hover {
  background-color: #89080e;
}
.short-text {
  /* margin-top: 12px; */
}
.short-text p {
  font-size: 9px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  /* margin-bottom: 7px; */
}
.links.o-land span {
  background-color: #fff;
}
.links.o-land {
  margin-top: 4px;
}
.links.o-land a:hover {
  color: #ba131a;
}
.landing-header-logo img {
  width: 100%;
  max-width: 115px;
}
.input__group.otpinput input {
  width: 100%;
  max-width: 20%;
  text-align: center;
  padding-left: 0px;
}
.form-wraper.otpformwraper .e-m-n {
  text-align: left;
}
.resendOtp .otp-recevied {
  color: #908b8b;
  font-size: 14px;
  margin-top: 20px;
}
.resendOtp .otp-recevied span {
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

.otp-seconds {
  display: flex;
  align-items: center;
}
.otp-seconds img {
  width: 100%;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.otp-seconds .hthree {
  color: #fff;
  font-size: 14px;
  margin-bottom: 0px;
  width: 39px;
}
.otpouterseconds {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.motp-recevied {
  color: #908b8b;
  font-size: 14px;
}

.lp-content {
  position: fixed;
  display: flex;
  width: 100%;
  left: 0;
  right: 0;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: #061c17 !important;
}

.lp-content .c-div {
  background-image: url(../Assets/Images/landing-popup-bg.webp);
  background-size: cover;
  min-height: 300px;
  max-width: 300px;
  padding: 35px 15px;
  text-align: center;
  border-radius: 15px;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  background-color: black !important;
  border-radius: 4px;
}

.lp-content .title {
  font-size: 19px;
  font-weight: 600;
  text-transform: uppercase;
}

.lp-content .title span {
  color: #07b2c7;
  font-weight: 600;
}

.lp-content button {
  width: 100%;
  float: left;
  background: linear-gradient(90deg, #03e57d, #07a6d8);
  color: #262626;
  font-size: 16px;
  font-weight: 600;
  padding: 15px 0px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  text-transform: capitalize;
}

.lp-content button:hover {
  color: #fff;
  transition: all 0.2s ease-in-out;
}
