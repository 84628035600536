.outerDiv.browserVideo.top-padding {
    height: auto;
}

.tagitems {
    background: #4C4C4C;
    border-radius: 28px;
    padding: 5px 14px;
    font-weight: 400;
    font-size: 13px;
    text-align: center;
    letter-spacing: 0.748px;
    color: #FFFFFF;
    margin-right: 10px;
    cursor: pointer;
}

.tagitems:hover {
    background: #3c3737;
}

.tagsBtns {
    display: flex;
    justify-content: flex-start !important;
}

.browseInfo {
    display: flex;
    column-gap: 12px;
    margin: 15px 0 25px;
}

.browseInfo p {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 1.309px;
    color: #C4C4C4;
}

.browseInfo span {
    font-weight: 400;
    color: #C4C4C4;

}

.browseInfo img {
    width: 30px;
    height: 30px;
}

.screenShotsWraper .d-btn-play2 {
    width: 100%;
    max-width: 200px;
}

.videoText {
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
    color: #C4C4C4;
}

.browseVideoCard {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
    backdrop-filter: blur(22px);
    border-radius: 14px 14px 20px 20px;
}

.browseVideoCard .b-vid-text {
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.374px;
    color: #FFFFFF;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* Number of lines to show before truncation */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.stDetail .card-text {
    color: #fff;
    font-size: 12px;
}

.browseMainhead {
    font-weight: 800;
    font-size: 60px;
    text-transform: uppercase;
    color: #FFFFFF;
    position: relative;
}

.browseMainhead::after {
    content: attr(data-text);
    -webkit-text-stroke: 1px #fff;
    position: absolute;
    left: 0;
    top: -4px;
    color: transparent;
    z-index: 5;
}

img.card-img-top.browseCardImg {
    
    border-radius: 14px;
    /* height: 172px; */
    cursor: pointer;
}

@media (min-width:340px) and (max-width:768px) {
    .browseMainhead {
        font-size: 40px;
    }

    .browseMainhead::after {
        content: none;
    }

    .shotHeadingContainer {
        margin-top: 0px;
        padding-bottom: 15px;
    }

    .browseVideoCard.cardImgLiveStrm {
        object-fit: unset;
    }

    .videoText {
        margin-bottom: 20px;
    }

    .tagsBtns {
        justify-content: center !important;
        margin-bottom: 30px;
    }

    .browseCardImg {
        width: 100%;
        height: auto;
        border-bottom: 2.80482px solid #FFFFFF;
        border-radius: 12.63px;
    }

    .stDetail .card-text {
        color: #fff;
        font-size: 11px;
    }
    .browseInfo{
        justify-content: center;
        column-gap: 6px;
    }
    .browseInfo p{
        font-size: 15px;
    }
}