.shotDesc {
  font-size: 13px;
  line-height: 26px;
  margin-bottom: 25px;
  text-transform: capitalize;
  color: #c4c4c4;
}

/* gc styling */

/* background-image: url("../Assets/Images/game-inner-page-banner.webp"); */
/* background-image: url("../Assets/Images/game-desc-bg.webp"); */

.gameDetailWraper {
  background-image: url("../Assets/Images/game-inner-page-banner.webp");
  background-size: cover;
  padding: 150px 0px 80px;
  background-position: center;
}
.gameDescWraper {
  background-image: url("../Assets/Images/game-desc-bg.webp");
  background-size: cover;
  background-position: center;
}
.gameDetailImg {
  border-radius: 30px;
  border: 3px solid #949494;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
  position: relative;
  z-index: 2;
  max-width: 300px;
}
.abs-text {
  position: relative;
}
.game-absolute-title {
  z-index: 1;
  font-size: 75px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-text-stroke: 1px #657774;
  color: transparent;
  font-family: "Dela Gothic One", sans-serif !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.gameDetailWraper {
  text-align: center;
}
.screenShotsWraper {
  margin: 20px 0px 40px;
}
.screenShotsWraper .mainHead {
  margin-bottom: 30px;
}
/* gc styling */
.abt-game {
  color: #fff;
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  margin-bottom: 10px;
}
@media (min-width: 340px) and (max-width: 768px) {
  .game-absolute-title{
    display: none;
  }
  .gameDetailWraper{
    background-position: right;
  }
}
