/* ------------------------------------ ScreenShot  ------------------------------------ */
/* new game page banner */
.game-page-banner {
  position: relative;
}

.game-page-banner img {
  width: 100%;
}

.banner__Wraper {
  position: absolute;
}

.banner__Wraper {
  position: absolute;
  top: 30%;
  left: 7%;
}

.banner__Wraper .shortText {
  text-align: left;
}

.banner__Wraper .mainHead {
  text-align: left;
  width: 100%;
  line-height: 60px;
}

.gamePagebannerBtn {
  margin-top: 40px;
}

/* new game page banner */
.games-page-bg {
  background-size: cover;
  background-image: url("../Assets//Images/games-page-bg.webp");
  background-position: center;
}

/* tabs */
.gamestabs {
  border-top: 1px solid #4e5150;
  border-bottom: 1px solid #4e5150;
  background: linear-gradient(180deg,
      rgba(18, 13, 38, 0.1) 0%,
      rgba(0, 0, 0, 0) 100%),
    rgba(255, 244, 243, 0);
  mix-blend-mode: screen;
  backdrop-filter: blur(107.16654205322266px);
  padding: 10px 0px;
}

.gamestabs button {
  background-color: transparent;
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.374px;
  border: none;
}

.gc-tab-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 70px;
}

.gamestabs button img {
  display: block;
  margin: 0 auto 10px;
  height: 22px;
}

.gc-tab-btn button.active {
  text-decoration: underline;
  text-underline-offset: 14px;
  text-decoration-color: #03E57D;
}

/* tabs */

.gwLogoDim {
  /* height: 93px;
  width: 113px; */
}

.priTextColor {
  color: white;
  font-size: 17px !important;
}

.cardBtnShGame {
  /* border-radius: 20px; */
  border: 0px;
  background: #4c4c4c;
  border-radius: 14px;
  color: white;
  padding: 3px 10px;
  font-size: 10px;
  /* width: 86px !important; */
  cursor: pointer !important;
  margin-right: 3px;
  margin-bottom: 5px;
}

.cardBtnShGame:hover {
  background: #3c3737;
}

.cardBtnAction {
  border-radius: 20px;
  border: 0px;
  background: #4c4c4c;
  border-radius: 14px;
  color: white;
  font-size: 9px;
  width: 48px !important;
  cursor: default !important;
}

.cardBtnLvlUp {
  border-radius: 20px;
  border: 0px;
  background: #4c4c4c;
  border-radius: 14px;
  color: white;
  font-size: 9px;
  width: 53px;
  cursor: default !important;
}

.cardGamBtnMrktPlace {
  font-size: 10px;
  padding-left: 13px;
  padding-right: 14px;
  padding-top: 5px;
  font-weight: 600;
  border-radius: 25px;
  /* padding: 4px 32px; */
  color: #fff;
  border: solid 3px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)),
    linear-gradient(101deg, #cd3aff, #1eccd6);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #3f254a inset, 0 5px 10px rgba(0, 0, 0, 0.5);
  transition: all 0.2s;
  width: 96px;
  height: 29px;
  /* margin-left: 18px; */
  margin-top: 2px;
}

.bannerBtns {
  display: flex;
  gap: 20px;
}

.bannerBtns .d-btn {
  width: 100%;
  max-width: 210px;
  padding: 13px 5px;
}

.bannerBtns .downloadgame {
  background-color: #b48cd7;
  padding: 12px 24px;
  border-radius: 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  border: none;
}

.hero-mobile {
  display: none;
}

/* banner section */
/* m styling */
.gameCardImg {
  border-radius: 12px !important;
  cursor: pointer;

  border-radius: 14px;
}

.gamingCard {
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 0.05) 0%,
      rgba(255, 255, 255, 0) 100%) !important;
  backdrop-filter: blur(22px) !important;
  border-radius: 14px 14px 20px 20px !important;
  overflow: hidden;
}

/* loading Spinner */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid lightgrey;
  /* Light grey */
  border-top: 10px solid #3c7fa1;
  /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px;
}

.nav-tabs .nav-link {
  border: unset !important;
  outline: none !important;
}

.nav-item button.nav-link:hover {
  color: #fff !important;
}

.shootActionBtn {
  margin-top: 10px;
}

/* ul.gameTabBtn.border-0.nav.nav-tabs {
    width: 100%;
    max-width: 100%;
    background-color: #2D1F48;
    padding: 15px 0px;
} */
.gc-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  width: 108px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
}

.padding-container {
  padding: 40px 0px;
}

.ls-gc {
  padding: 16px 0px 60px;
}

.ls-gc .gamingCard .card-body .gc-title {
  width: unset;
}

.gc-searchIcon {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.gc-searchIcon input {
  background-color: #1D2420;
  padding: 12px;
  border: none;
  border-radius: 22px;
  width: 100%;
  max-width: 33%;
  outline: none;
  color: #fff;
}

button#searchButton {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
}

#searchButton svg {
  fill: #CAC4D0;
}

.gc-gameSearch {
  margin-top: 30px;
}

@media (max-width: 1199px) {
  .mainCharacterBlur {
    display: none !important;
  }

  .padding-container {
    padding: 20px 0px;
  }

  .gamingCard .card-body .mb-3 {
    margin-bottom: 0px !important;
  }

  .bannerWrapper .mainHead {
    font-size: 94px;
  }

  .mainCharacter {
    z-index: 0;
  }

  .bannerBtns {
    z-index: 34;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 12px;
  }

  .gwLogoDim {
    display: none !important;
  }
}

@media (min-width: 340px) and (max-width: 767px) {
  .gc-searchIcon input {
    max-width: 100%;
  }

  .gc-tab-btn {
    column-gap: 0;
  }

  .gamestabs button {
    font-size: 13px;
    font-weight: 400;
  }

  .gc-tab-btn button.active {
    text-decoration: none;
    color: #03E57D;
  }

  .gc-title {
    width: 65%;
  }

  .bannerBtns {
    display: unset;
    position: absolute;
    top: 56%;
    left: 10px;
  }

  .bannerBtns button {
    display: block;
    margin-bottom: 5px;
  }

  .bannerBtns .downloadgame {
    padding: 7px 10px;
    font-size: 9px;
    height: 35px;
  }

  .bannerBtns {
    width: 80%;
    display: flex;
    justify-content: flex-start;
    gap: 9px;
    top: 48%;
  }

  .bannerBtns .d-btn {
    max-width: fit-content;
    width: auto;
    font-size: 12px;
    padding: 7px 10px;
  }

  .banner__Wraper {
    position: unset;
  }

  .banner__head {
    font-size: 55px;
    text-align: center;
  }

  .shootActionBtn {
    width: 100%;
    max-width: 100%;
    overflow-x: scroll;
    min-height: unset;
    display: flex;
  }

  .main-ch-mob {
    position: relative;
  }

  .main-ch-mob .artwork-img {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .shootActionBtn::-webkit-scrollbar {
    display: none;
  }

  .shootActionBtn .cardBtnShGame {
    min-width: max-content;
  }

  /* landing page responsiveness */
  .gameCardImg {
    cursor: pointer;
    width: 100%;
    /* object-fit: cover; */
  }

  .gamingCard {
    background: linear-gradient(270deg,
        rgba(255, 255, 255, 0.05) 0%,
        rgba(255, 255, 255, 0) 100%) !important;
    border-radius: 9.18px !important;
  }

  ul.gameTabBtn {
    width: 100%;
    max-width: 350px;
    white-space: nowrap;
    overflow-x: scroll;
    padding-bottom: 22px;
    margin-left: 12px;
    margin-bottom: 12px !important;
    flex-wrap: unset !important;
  }

  .d-block.d-sm-block.d-md-none {
    margin-top: 11px;
  }

  .outerDiv {
    position: relative;
    height: unset;
  }

  .bannerWrapper .mainHead {
    font-size: 33px;
  }

  .bannerWrapper {
    padding-top: 100px;
    text-align: center;
    margin-left: unset;
    margin-top: unset;
  }

  .bannerWrapper .mainHead::after {
    content: none;
  }

  .bannerWrapper .mainHead {
    width: 100%;
    max-width: 270px;
    font-size: 40px;
    line-height: 108%;
    margin: 0 auto;
  }

  .dummytext p {
    font-size: 11px;
    line-height: 158%;
    width: 100%;
    max-width: 90%;
    margin: 20px auto;
  }

  .bannerWrapper .popText {
    font-size: 11px;
    line-height: 13px;
  }

  .mainCharacter {
    display: none;
  }

  .mainCharacterBlur {
    display: none;
  }

  .hero-mobile {
    display: block;
    margin-left: auto;
    height: 325px;
  }

  .dis-mobile {
    display: block;
  }

  .dis-desk {
    display: none;
  }

  .shootActionBtn {
    gap: 30px;
  }

  /* landing page responsiveness ends */
}

@media (max-width: 360px) {
  .card.gamingCard {
    flex-direction: column;
  }

  /* .gc-title {
    width: 300px;
} */
  .shootActionBtn {
    max-width: 100%;
  }
}