.livestream .instant {
  font-size: 130px;
}
.livestream #text {
  font-size: 130px;
}
.streamerImg{
  width: 36px;
height: 36px;
border-radius: 15px;
}
/* .outerDivLiveStrm {
  background-repeat: no-repeat;
  background-image: url("../Assets/Images/top-banner-bg.webp") !important;
  height: 100%;
   margin-bottom: 60px;
} */
.LiveStrmTitFont {
  font-size: 14px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  width:  100%;
  height: 1.2em;
  white-space: nowrap;
  color: #ffffff;
}
.cardImgLiveStrm {
  border-radius: 15px !important;
  /* height: 151px; */
  border-bottom: 2px solid rgba(255, 0, 254, 1);
}
/* .cardImgLiveStrm:hover{
    transform: scale(1.06) !important;
    transition: .3s;
} */
.cardLiveStrm.card{
    overflow: hidden;
     background: linear-gradient(0deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
    backdrop-filter: blur(22px);
    border-radius: 14px 14px 20px 20px;
    cursor: pointer;
}
.streamersInfo {
  display: flex;
  column-gap: 10px;
  align-items: center;
  margin-top: 10px;
}
.streamersInfo p {
  margin-bottom: 0px;
}
.tabsItems.liveStream {
    background-color: transparent !important;
    backdrop-filter: unset;
    padding: 160px 0px 0px;
    margin-bottom: 30px;
    /* height: 100vh; */
}
.mobileCenterLiveStrm{
padding: 0px 0px 60px;
}
@media (min-width: 340px) and (max-width: 767px) {
  .tabsItems.liveStream{
    padding: 80px 0px 0px;
  }
  .streamersInfo p {
    font-size: 11px;
  }
  .outerDivLiveStrm {
    background-image: none !important;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.05) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  .livestream .instant {
    font-weight: 800;
    font-size: 63.5004px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    -webkit-text-fill-color: unset;
  }
  .livestream .gradient-text {
    background: none;
    -webkit-text-fill-color: unset;
    font-weight: 800;
    font-size: 35px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }
  .livestream.instanthead {
    width: 100%;
    max-width: 300px;
    display: grid;
    place-items: center;
    margin: 0 auto;
    padding-top: 100px;
  }
  .cardLiveStrm {
    flex-direction: revert;
    width: 100%;
    }
    .cardImgLiveStrm {
    width: 100%;
    max-width: 200px;
    /* object-fit: contain; */
    height: auto;
    }
  .LiveStrmTitFont {
     display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to display before truncating */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: unset;
    height: unset;
    white-space: unset;
    }
    /* .streamersInfo {
      column-gap: 10px;
    } */
}
@media (max-width:399px) {
  .cardLiveStrm {
    flex-direction: column;
  }
  .cardImgLiveStrm {
    max-width: 100%;
  }
}