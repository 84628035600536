@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap");
.subs-screen {
  font-family: "Montserrat", sans-serif;
}
img {
  max-width: 100%;
  height: auto;
}
/* background */
.animated-bg {
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  background-image: url(../Assets/Images/landing-bg.webp);
  background-size: cover;
  background-repeat: no-repeat;
}
.animated-bg .lines {
  text-align: center;
  height: 0;
}
.animated-bg .lines span {
  width: 5px;
  height: 5px;
  margin: 0 25px;
  background: #18ffff;
  border-radius: 10px;
  position: relative;
  display: inline-block;
  animation: animate 15s linear infinite;
  animation-duration: calc(225s / var(--i));
  opacity: 0.3;
}
@keyframes animate {
  0% {
    transform: translateY(120vh);
  }
  100% {
    transform: translateY(-100vh);
  }
}
.animated-bg .lines span::after {
  content: "";
  position: absolute;
  background: linear-gradient(
    #18ffff,
    #33afff,
    #4873ff,
    #6227ff,
    #651fff,
    #d500f9,
    transparent,
    transparent
  );
  width: 1px;
  height: 550px;
  left: 2px;
}

.logo {
  position: relative;
  margin-top: 10px;
}
.logo img {
  /* width: 110px; */
  position: absolute;
}
.subscribeBox {
  width: 100%;
  border-radius: 35px;
  position: relative;
  background: linear-gradient(
    60deg,
    rgba(3, 229, 125, 1),
    rgba(7, 166, 216, 1)
  );
}
.subOuterbox {
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.subscribeBox::after {
  content: "";
  position: absolute;
  top: 50%;
  background-image: url("../Assets/Images/card-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  left: 50%;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  z-index: 2;
  border-radius: 32px;
  transform: translate(-50%, -50%);
}
.subinnerBox {
  z-index: 99;
  position: relative;
  padding: 50px 30px 30px 30px;
}
.subscriptionText h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #ffffff;
  margin: 0;
}
.subscriptionText .getotp {
  font-size: 13px;
  color: #ffffff;
  margin: 20px 0px;
  text-align: center;
}
.subscribeBox .subinnerBox .tel-field {
  width: 75%;
}

.subscribeBox .subinnerBox .cform-control.rounded {
  background: #343434;
  border: 1px solid #07a6d8;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px !important;
  padding: 10px;
}
.subscribeBox .subinnerBox .cform-control.rounded::placeholder {
  color: #ffff;
}
.subscribeBox .subinnerBox .input-group-text {
  background: #343434;
  border: 1px solid #07a6d8;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  color: #fff;
  padding: 10px;
}
.otpBtn {
  width: 100%;
  font-weight: 700;
  background: linear-gradient(90deg, #03e57d, #07a6d8);
  display: block;
  margin: 0 auto;
  border-radius: 13px;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 10px;
  padding: 8px 0px;
  position: relative;
  z-index: 1000;
}

.taxpermonth {
  font-size: 11px;
  line-height: 22px;
  text-align: center;
  color: rgba(255,255,255,0.9);
  margin-top: 20px;
}
.otpInput .input-fields {
  display: flex;
  justify-content: space-between !important;
  margin-bottom: 20px;
}
.otpInput input {
  background: #343434;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.16);
  border: 1px solid #07a6d8;
  border-radius: 10px;
  width: 50px !important;
  height: 50px !important;
  color: #ffff;
  outline: none;
  margin: 0 !important;
  z-index: 10000;
}
.subinnerBox::before {
  content: "";
  width: 200px;
  height: 200px;
  margin-right: 5px;
  /* background-image: url("../Assets/Images/gamecontroller.png"); */
  background-image: url("../Assets/Images/game-controller.webp");
  position: absolute;
  top: -69px;
  left: -64px;
  background-size: contain;
  background-repeat: no-repeat;
}
.otpinput-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.round-gradient {
  width: 97.5%;
  height: 98%;
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  inset: 5px;
  background-image: radial-gradient(
    circle,
    transparent,
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.7)
  );
  border-radius: 32px;
}
.round-gradient::before {
  content: "";
  position: absolute;
  width: 4px;
  height: 170px;
  background: linear-gradient(0deg, transparent, #fff, #fff, transparent);
  left: -4px;
  top: 60px;
  border-radius: 50%;
  box-shadow: 0 27px 22px rgba(255, 255, 255, 1),
    0 27px 30px rgba(255, 255, 255, 1), 0 27px 40px rgba(255, 255, 255, 1);
}
.round-gradient::after {
  content: "";
  position: absolute;
  width: 4px;
  height: 170px;
  background: linear-gradient(0deg, transparent, #fff, #fff, transparent);
  right: -4px;
  bottom: 60px;
  border-radius: 50%;
  box-shadow: 0 27px 22px rgba(255, 255, 255, 1),
    0 27px 30px rgba(255, 255, 255, 1), 0 27px 40px rgba(255, 255, 255, 1);
}
.resend-otp {
  font-size: 14px;
  text-align: center;
  color: #c4c4c4;
  margin-top: 20px;
}
.resend-otp span {
  color: #fff;
}
.resend-otp span:hover {
  cursor: pointer;
  color: rgba(70, 158, 188, 1);
  transition: all 0.2s;
}
/*privacy policy*/
.links {
  text-align: center;
}
.links a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  text-decoration: underline;
}
.links a:hover {
  color: rgba(70, 158, 188, 1);
  transition: all 0.2s;
}
.links span {
  background: rgba(70, 158, 188, 1);
  height: 8px;
  width: 8px;
  display: inline-block;
  border-radius: 50%;
  margin: 0 8px;
}
.subs-error {
  text-align: center;
  margin: 10px 0;
  font-size: 12px;
  color: #fff;
}
.show-count {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.show-count svg {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.show-count h1 {
  color: #fff;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
}
.m-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 6px;
}
.otpseconds {
  width: 38px;
}
.show-count span {
  width: 46px;
  cursor: unset !important;
  color: #fff;
  font-size: 14px;
}
.resend-otp {
  display: flex;
  justify-content: center;
}
.resend-otp svg {
  width: 15px;
  height: 15px;
}
.m-text {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
/* new param landing page css */
.subinnerBox.newparam::before {
  content: none;
}
.newParamWraper {
  margin-top: 50px;
  margin-bottom: 70px;
}
.newParamWraper .subOuterbox {
  position: unset;
  transform: unset;
}
.newParamWraper .row {
  align-items: center;
}
.newParamWraper .subOuterbox .subinnerBox.newparam {
  padding: 30px;
}
.subinnerBox.newparam .subscriptionText .getotp {
  margin-top: 0px;
}
.pwgImg {
  text-align: center;
  z-index: 20;
  position: relative;
}
.pwgImg img {
  width: 80%;
}
.paralogo img {
  position: unset;
}
/* new param landing page css */
/* game club */
.paralogo {
  text-align: center;
  margin-top: 50px;
}
.absoluteImgs {
}
.absoluteImgs .gamecontroller {
  position: absolute;
  z-index: 99;
  top: -60px;
  left: -60px;
  width: 180px;
}
.absoluteImgs .gamecharacters {
  position: absolute;
  z-index: 99;
  bottom: -71px;
  left: -204px;
  width: 280px;
}
.absoluteImgs .vr-img {
  position: absolute;
  z-index: 999;
  bottom: -24px;
  right: -132px;
  width: 202px;
}

/* game club */

/* Responsive */
@media (max-width: 998px) {
  .newParamWraper {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .animated-bg {
    min-height: 100vh;
    height: 100%;
    padding-bottom: 50px;
  }
  .logo {
    text-align: center;
    margin-bottom: 10px;
  }
  .logo img {
    width: 120px;
  }
  .subOuterbox {
    max-width: 330px;
    position: inherit;
    transform: translate(0, 0);
    margin-top: 120px;
  }
  .newParamWraper .subOuterbox {
    margin-top: 0px;
  }

  .subinnerBox::before {
    left: -23px;
    top: -37px;
    width: 100px;
  }
  .subinnerBox {
    padding: 40px 20px;
  }
  .absoluteImgs,
  .paralogo img,
  .cardImg {
    display: none;
  }
  .subscribeBox,
  .round-gradient,
  .subscribeBox::after {
    border-radius: 15px;
  }
  .paralogo {
    margin-top: 30px;
  }
  .round-gradient {
    width: 96.5%;
  }
}
