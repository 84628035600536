.m-stram-card{
    background: linear-gradient( 0deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100% ) !important;
    backdrop-filter: blur(22px);
    margin-bottom: 100px;
    padding-bottom: 10px;
}
.m-stram-card .str_img{
    width: 120px;
    height: 120px;
    margin-top: -30px !important;
    margin: auto;
    border-radius: 50%;
}
.m-stram-card .LiveStrmTitFont{
    font-weight: 700;
    font-size: 18px !important;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.374px;
    color: #FFFFFF;
    margin: 0 auto;
    -webkit-line-clamp: 1;
}
.m-stram-card .subscribers{
    font-weight: 300;
    font-size: 14px;
    color: #FFFFFF;
    text-align: center;
    margin: 5px 0 15px 0;
}
.m-stram-card button{
display: block;
margin: 0 auto;
}

@media (min-width: 340px) and (max-width: 767px) {
  .m__strea .instanthead.livestream
   {
    display: flex;
    justify-content: center;
   }
   .m__strea {
    padding: 0px 0px 60px 0px;
    }
    .m__strea .livestream .gradient-text, .m__strea .livestream .instant {
        font-size: 35px;
    }
   
    .m-stram-card .LiveStrmTitFont {
    font-size: 15px !important;
    line-height: 20px;
    }
    .m-stram-card .subscribers {
    font-size: 13px;
    margin-bottom: 10px;
    }
    .m-stram-card button {
    display: block;
    margin: 0 auto;
    padding: 6px 33px;
    }
    .m-stram-card .str_img {
        width: 60px;
        height: 60px;
    }
    button.downloadBtn{
          padding: 8px 16px !important;
    }
    .m-stram-card {
    margin-bottom: 65px;
    }
}   
