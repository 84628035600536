.user-profile-bg {
  background-repeat: no-repeat;
  background-image: url("../Assets/Images/user-profile-bg.webp") !important;
  background-size: cover !important;
}
.userSettingSection {
  padding: 150px 0px 60px;
}
.uploadAvatar {
  padding: 30px 40px;
  border-radius: 18.562px;
  border: 3px solid #343434;
  background: rgba(44, 44, 44, 0.4);
  backdrop-filter: blur(28.6200008392334px);
}
.choseFile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.d-btn {
  cursor: pointer;
}
.Ua-wraper {
  display: flex;
  flex-direction: column;
  text-align: center;
  row-gap: 20px;
  align-items: center;
}
.avatarImg {
  position: relative;
}
.Ua-wraper .avatarImg img {
  width: 80px;
  height: 80px;
  border-radius: 30px;
  object-fit: contain;
  position: relative;
  z-index: 2;
}
.Ua-wraper .avatarImg::before {
  content: "";
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(90deg, #03e57d, #07a6d8);
  width: 85px;
  height: 85px;
  border-radius: 30px;
  object-fit: contain;
  border-radius: 50%;
  z-index: 1;
}
.b-info {
  color: #fff;
  font-family: Urbanist;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-left: 3px solid #03e57d;
  padding-left: 12px;
}
.Ua-wraper .disclaimerText {
  color: #fff;
  opacity: 0.6;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 20px;
}
.formWraper {
  margin-top: 30px;
  transition: height 0.3s ease;
}
.formWraper label {
  display: block;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
}
.input__Group {
  width: 100%;
  margin-bottom: 30px;
  max-width: 100%;
}
.user-input {
  width: 100%;
  padding-left: 20px;
  outline: none;
  color: #babebd;
  height: 50px;
  border: 1px solid #babebd;
  background-color: #343434;
  border-radius: 12px;
}

.input__Group.fullwidth {
  width: 100%;
  max-width: 100%;
}
.input__Group textarea {
  border-radius: 12px;
  color: #babebd;
  width: 100%;
  outline: none;
  padding-left: 20px;
  outline: none;
  height: 120px;
  border: 1px solid #babebd;
  background-color: #343434;
}
.saveChanges .d-btn {
  width: 100%;
}
.shotnote {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #c4c4c4;
  padding-top: 4px;
}
.streamingKey {
  padding: 30px;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(22px);
  border-radius: 20px;
  margin-top: 60px;
}
.saveChanges {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.input__Group.streamkey {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
}
.sk {
  width: 100%;
  max-width: 79%;
  margin-top: 30px;
}
input#file-input {
  color: #fff;
  cursor: pointer;
}
p.error {
  color: #ff5858;
  font-size: 14px;
  padding-left: 12px;
}
.error-Msg {
  color: #babebd;
  margin-top: 12px;
  text-align: center;
}
@media (min-width: 340px) and (max-width: 767px) {
  .userProfile:focus .navbar-nav {
    display: none;
    background-color: red;
  }
  .uploadAvatar {
    padding: 20px 15px;
    margin-bottom: 30px;
  }

  .Ua-wraper .disclaimerText {
    font-size: 11px;
    width: 100%;
    max-width: 100%;
    line-height: unset;
    margin-top: 16px;
  }
  .input__Group {
    max-width: 100%;
  }
  .ft-input {
    flex-direction: column;
  }
  .sk {
    max-width: 100%;
    margin-bottom: 30px;
  }
  .input__Group.streamkey {
    flex-direction: column;
  }
  .generateBtn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .input__Group {
    margin-bottom: 20px;
  }
  .user-input {
    height: 35px;
  }
  .userSettingSection {
    padding: 100px 0px 40px;
  }
}
