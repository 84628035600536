.awareness-page {
  background-image: url("../Assets/Images/game_club_awareness_page_web.webp");
  min-height: 100vh;
  padding-bottom: 10px;
  background-size: cover;
  background-repeat: no-repeat;
}
.awareness-page .image {
  max-width: 375px;
  margin: auto;
  padding-top: 20px;
}

.awareness-page .image img {
  width: 100%;
}
@media (max-width:767px) {
    .awareness-page {
    background-image: url("../Assets/Images/gc-awr-mobile.webp");
    }
}