.vid__Streamer video {
  width: 100%;
  border-radius: 9px;
}

.VideoouterDiv {
  background-repeat: no-repeat;
  /* background-image: url("../Assets/Images/main-bg.webp") !important; */
  background-size: cover !important;
}

.videoStreamSection {
  padding-top: 170px;
  padding-bottom: 60px;
}

.streamerProfile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.userDetail {
  display: flex;
  column-gap: 20px;
  align-items: center;
}

.userName .userLive {
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.245174px;
  color: #FFFFFF;
  margin-bottom: 0px;

}

.userName .userViews {
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0.245174px;
  color: #FFFFFF;
  margin-bottom: 0px;
}

.userImg img {
  width: 36px;
  border-radius: 50%;
  height: 36px;
}

.recommendedStreams {
  border-radius: 17px;
  padding: 40px 22px;
  background: rgba(78, 51, 98, 0.5);
  backdrop-filter: blur(22px);
}

.recomendedHeading {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.374px;
  color: #FFFFFF;
}

p {
  margin-bottom: 0px;
}

.streamsAllButton button {
  background: #4C4C4C;
  outline: none;
  border-radius: 14px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.374px;
  color: #FFFFFF;
  padding: 5px 10px;
  max-width: max-content;
  margin-right: 12px;
  border: none;
}

.streamsAllButton {
  width: 100%;
  max-width: 310px;
  white-space: nowrap;
  overflow-x: scroll;
  margin-top: 25px;
  padding-bottom: 20px;
}

.rec__videos {
  border-radius: 9.18px;
  display: block;
  background-color: #5A3D61;
  display: flex;
  margin-top: 20px;
  text-decoration: none;
}

.center_videoImg {
  width: 100%;
  max-width: 155px;
  height: auto;
  border-radius: 9px;
  border-bottom: 1px solid rgba(255, 255, 255, 1);
}

.recomendedVideos {
  margin: 30px 0px;
}

.streamerTitle {
  padding: 12px 10px;
}

.streamDesc {
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.245174px;
  color: #FFFFFF;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Adjust the number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.new.userLive {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 80px;
}

.challenges img {
  width: 80%;
}

.new.userLive,
.new.userViews {
  font-size: 10px;
}

.new.userDetail {
  margin-top: 10px;
  column-gap: 5px;
}

.new.userImg {
  width: auto;
  height: auto;
}

.new.userImg img {
  width: 25px;
  height: unset;
}

.seeallbtn {
  background-color: #b48cd7;
  border-radius: 20px;
  width: 100%;
  max-width: 150px;
  color: #fff;
  padding: 4px 0px;
  border: 0px;
  outline: none;
  margin-left: 20px;
}

.challengesWraper {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  column-gap: 25px
}

.challengesContent p {
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.245174px;
  color: #FFFFFF;
  width: 100%;
  max-width: 351px;
}

.challengesContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.challengesContent span {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.374px;
  color: #FFFFFF;
  /* margin: 20px 0px; */
  display: block;
}

.rec__videos:last-child {
  margin-bottom: 0px;
}

.recomendedVideos {
  margin: 30px 0px 0px;
  height: 635px;
  overflow-y: scroll;
}

.recomendedVideos::-webkit-scrollbar {
  display: none;
}

/* width */
.streamsAllButton::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

/* Track */
.streamsAllButton::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.streamsAllButton::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.streamsAllButton::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.chat-box {
  width: 100%;
  height: 500px;
  margin-bottom: 20px;
  color: #fff;
  background: linear-gradient(to bottom, rgba(86, 55, 96, 0.7), rgba(0, 0, 0, 0.9));
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(22px);
  border-radius: 36px;
  padding: 22px;
  /* background: rgba(86, 55, 96, 0.7); */
  background: linear-gradient(to bottom, rgba(86, 55, 96, 0.7) 40%, rgba(0, 0, 0, 0.9) 90%);

}

.challenges img {
  width: 100%;
  max-width: 200px;
  border-radius: 16.1481px 16.1481px 12.9185px 12.9185px;
}

.chat-messages {
  flex-grow: 1;
  margin-bottom: 10px;
  overflow-y: scroll;
  padding: 20px 0px;
}

.chat-messages::-webkit-scrollbar {
  display: none;
}

.chat-input {
  display: flex;
  align-items: center;
  position: relative;
}

.chat-input input {
  flex-grow: 1;
  padding: 5px;
  border: none;
  outline: none;
  color: #fff;
  background: #4C4C4C;
  border-radius: 30px;
  padding: 12px 0px 12px 40px;
}



/* Styling for chat messages (optional) */
.chat-message {
  margin-bottom: 10px;
}

.chatboxTop {
  padding: 20px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #4C4C4C;
}

.chatboxTop p {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.374px;
  color: #FFFFFF;
}

.msgsender img {
  width: 50px;
  height: 50px;
}

.msgsender {
  display: flex;
  width: 100%;
  column-gap: 20px;
  align-items: flex-start;
  margin-bottom: 25px;
}

.progImg {
  position: absolute;
  top: 50%;
  left: 10px;
  height: 24px;
  width: 24px;
  transform: translateY(-50%);
}

img.sendmsgIcon {
  position: absolute;
  right: 20px;
  width: 24px;
  height: 24px;
}

.sinfo span {
  font-weight: 300;
  font-size: 13px;
}

.userSpan {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .VideoouterDiv {
    background-image: none;
  }

  .videoStreamSection .col-md-8 {
    width: 100%;
  }

  .videoStreamSection .col-md-4 {
    width: 65%;
    margin: 34px auto 0px;
  }
}

@media (min-width: 340px) and (max-width: 767px) {
  .videoStreamSection {
    padding-top: 100px;
  }
  .VideoouterDiv {
    /* background-image: url("../Assets/Images/main-bg-mob.webp") !important; */
    background-size: cover;
  }
  .vid__Streamer video {
    border-radius: 0px;
  }

  .videoStreamSection .col-md-8 {
    padding: unset;
  }

  .streamerProfile {
    padding: 0 15px;
  }

  .userName .userLive {
    font-size: 13px;
  }

  .userName .userViews {
    font-size: 12px;
  }

  .challengesWraper {
    padding: 0 15px;
    column-gap: 10px;
  }

  .challenges img {
    width: 100%;
    max-width: 130px;
  }

  .challengesContent p {
    font-size: 16px;
  }

  .challengesContent {
    gap: 10px;
  }

  .challengesContent span {
    font-weight: lighter;
    font-size: 14px;
  }

  .seeallbtn {
    display: none;
  }

  .chat-box {
    display: none;
  }

  .recommendedStreams {
    background: none;
    padding: 40px 0px 10px;
  }

  .streamsAllButton {
    display: none;
  }

  .rec__videos {
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
    backdrop-filter: blur(22px);
  }
}